import { Loader } from '@intentic/ts-foundation';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { ComoRewardsStore } from './ComoRewardsStore';
import { InitializedComoRewardsContent } from './InitializedComoRewardsContent';

interface ComoRewardsContentProps
{
	store: ComoRewardsStore;
}

export const ComoRewardsContent: FC<ComoRewardsContentProps> =
	({
		store,
	}) =>
	{
		const initialized = useObserver(() => store.isInitialized);

		useEffect(() =>
		{
			if (!initialized)
				store.initializeStore()
		}, [initialized, store]);

		if (initialized)
		{
			return <InitializedComoRewardsContent store={store}/>
		}
		else
		{
			return <Loader/>;
		}
	};
