import * as React from 'react';
import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, makeObservable, observable } from 'mobx';
import { LoyaltyDiscount } from '../../../../../Api/Order/Loyalty/LoyaltyDiscount';
import { Bridge } from '../../../../../Bridge/Bridge';
import { EntranceStore } from '../../../Entrance/EntranceStore';
import { BusinessStore } from '../../BusinessStore';
import { CouponCardStore } from '../CouponCard/CouponCardStore';
import { Coupon } from '../../../../../Api/Order/Coupon/Coupon';
import { CouponBadgeStore } from '../CouponBadge/CouponBadgeStore';
import { CurrentOrderService } from '../../../../../Service/CurrentOrder/CurrentOrderService';
import { IllegalStateException } from '../../../../../Util/Exception/IllegalStateException';
import { Place } from '../../../../../Api/Business/Place';

export class CouponFormStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    bridge: Bridge;
    currentOrderService: CurrentOrderService;
    entranceStore: EntranceStore;
    businessStore: BusinessStore;
    showValidation: boolean;
    textField: React.RefObject<any>;
    onClose: () => Promise<void>;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(
        bridge: Bridge,
        currentOrderService: CurrentOrderService,
        entranceStore: EntranceStore,
        businessStore: BusinessStore,
        onClose: () => Promise<void>,
    )
    {
        super();

        makeObservable<CouponFormStore, 'requiresCouponCode'>(
            this,
            {
                bridge: observable,
                currentOrderService: observable,
                entranceStore: observable,
                businessStore: observable,
                showValidation: observable,
                textField: observable,
                onClose: observable,
                place: computed,
                summaryLabel: computed,
                explanationText: computed,
                addedCouponsText: computed,
                label: computed,
                couponCodeValidation: computed,
                validation: computed,
                hasActiveCoupons: computed,
                addedCouponsStores: computed,
                addedCouponBadgeStores: computed,
                canAddCoupon: computed,
                couponCode: computed,
                supportsCouponCode: computed,
                requiresCouponCode: computed,
                removeCoupon: action.bound,
                removeLoyaltyDiscountCoupon: action.bound,
                afterOrder: action.bound,
                onChangeCouponCode: action.bound,
                setShowValidation: action.bound,
                listenToEnterKey: action.bound,
                setTextField: action.bound,
                focus: action.bound,
                blur: action.bound,
            },
        );

        this.bridge = bridge;
        this.currentOrderService = currentOrderService;
        this.entranceStore = entranceStore;
        this.businessStore = businessStore;
        this.showValidation = false;
        this.textField = React.createRef();
        this.onClose = onClose;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get place(): Place | undefined
    {
        return this.businessStore.place;
    }

    get summaryLabel(): string
    {
        return this.bridge.localizer.translate('Client-Coupon');
    }

    get explanationText(): string
    {
        return this.bridge.localizer.translate('Client-Coupon-Code-Explanation')
            + ':';
    }

    get addedCouponsText(): string
    {
        return this.bridge.localizer.translate('Client-Coupon-Code-WillBeUsedInOrder')
            + ':';
    }

    get label(): string
    {
        return this.bridge.localizer.translate('Client-Coupon-Code');
    }

    get couponCodeValidation(): string[]
    {
        if (this.currentOrderService.couponCode === null)
        {
            throw new IllegalStateException('No coupon codes found.');
        }

        return this.place !== undefined && this.requiresCouponCode && !this.hasActiveCoupons
            ? [this.bridge.localizer.translate('Order-Coupon-Required')]
            : [];
    }

    get validation()
    {
        return this.couponCodeValidation;
    }

    get hasActiveCoupons(): boolean
    {
        return this.currentOrderService.hasActiveCoupons;
    }

    get addedCouponsStores(): CouponCardStore[]
    {
        return this.currentOrderService.addedCoupons
            .map(coupon => new CouponCardStore(
                this.bridge.localizer,
                coupon.coupon,
                coupon.discount,
                () => this.removeCoupon(coupon.coupon)
            ));
    }

    get addedCouponBadgeStores(): CouponBadgeStore[]
    {
        return this.currentOrderService.addedCoupons
            .map(
                coupon =>
                    new CouponBadgeStore(
                        this.bridge.localizer,
                        coupon.coupon,
                        coupon.discount
                    )
            );
    }

	get addedLoyaltyDiscountCoupons(): LoyaltyDiscount[]
	{
		return this.currentOrderService.addedLoyaltyCoupons;
	}

	get canAddCoupon(): boolean
	{
		return !this.hasActiveCoupons;
	}

    get couponCode(): string
    {
        return this.currentOrderService.couponCode;
    }

    public get supportsCouponCode(): boolean
    {
        return this.place?.couponCodeSupport === 'DISALLOWED' || this.requiresCouponCode;
    }

    public get requiresCouponCode(): boolean
    {
        return this.place?.couponCodeSupport === 'REQUIRED';
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    public removeCoupon(coupon: Coupon): void
    {
        const match = this.currentOrderService.addedCoupons.find(d => d.coupon === coupon);
        if (match !== undefined)
        {
            this.currentOrderService.addedCoupons.remove(match);
        }
    }

    public afterOrder(): void
    {
        this.currentOrderService.setCouponCode('');
    }

    public onChangeCouponCode(couponCode: string): void
    {
        this.currentOrderService.setCouponCode(couponCode);
        this.currentOrderService.checkOrderIfNecessary();
    }

    public removeLoyaltyDiscountCoupon(discount: LoyaltyDiscount): void
    {
        const newDiscounts = this.currentOrderService.activeLoyaltyDiscount.discounts.filter(d => d.externalId !== discount.externalId);

        this.currentOrderService.setActiveLoyaltyDiscount({
            discounts: newDiscounts,
            lines: this.currentOrderService.activeLoyaltyDiscount.lines,
        })
        this.currentOrderService.checkOrderIfNecessary();
    }

    // ------------------------ Public logic ------------------------

	setShowValidation(showValidation: boolean)
    {
        this.showValidation = showValidation;
    }

    listenToEnterKey(ev: React.KeyboardEvent)
    {
        if (ev.key === 'Enter')
        {
            return this.onClose();
        }
    }

    setTextField(textField: React.RefObject<any>)
    {
        this.textField = textField;
    }

    focus()
    {
        if (this.textField && this.textField.current)
        {
            this.textField.current.focus();
        }
    }

    blur()
    {
        if (this.textField && this.textField.current)
        {
            this.textField.current.blur();
        }
    }

    exitsUI(isUnmounted: boolean)
    {
        if (isUnmounted)
        {
            this.blur();
        }
    }

    // ----------------------- Private logic ------------------------
}
