import { useObserver } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { Cart, CartProfile } from '../../../../../../../Api/Order/Cart/Cart';
import { CartDelete, CartDeleteProfile } from '../../../../../../../Api/Order/CartDelete';
import { CartOffline, CartOfflineProfile } from '../../../../../../../Api/Order/CartOffline';
import { CartPulse, CartPulseProfile } from '../../../../../../../Api/Order/CartPulse';
import { CartUpdate, CartUpdateProfile } from '../../../../../../../Api/Order/CartUpdate';
import { fetch, fetchList } from '../../../../../../../Util/Api';
import { useAsyncResult } from '../../../../../../../Util/async/useAsyncResult';
import { BusinessContextRef } from '../../../../BusinessContext';
import { useWebSocketQuery } from '../../../../useWebSocketQuery';
import { BusinessQuery } from '../../../../WebSocketService';

export function usePeerCarts(): Cart[]
{
	const {
		result: carts,
		setResult: setCarts,
	} = useAsyncResult(
		() =>
			fetchList(
				'/client/business/cart/peerCarts',
				undefined,
				CartProfile
			),
		[]
	);
	const {businessStore} = useContext(BusinessContextRef);
	const ownCartId = useObserver(() => businessStore.shoppingCartStore.currentOrderService.ownCartId);
	const businessId = useObserver(() => businessStore.business.id);
	const businessQuery = useMemo(() => new BusinessQuery(businessId), [businessId]);
	useWebSocketQuery(
		businessQuery,
		'cart_update',
		notification =>
		{
			const cartUpdate = CartUpdateProfile.deserialize(notification) as CartUpdate;

			if (cartUpdate.cart.id !== ownCartId)
			{
				const cart = cartUpdate.cart;

				setCarts(
					currentPeerCarts =>
						upsertCartInCarts(
							cart,
							currentPeerCarts ??[]
						)
				);
			}
		}
	);
	useWebSocketQuery(
		businessQuery,
		'CartPulse',
		async notification =>
		{
			const cartPulse = CartPulseProfile.deserialize(notification) as CartPulse;

			if (cartPulse.cartId !== ownCartId)
			{
				let isCartNotYetInPeerCarts = false;

				setCarts(
					currentPeerCarts =>
					{
						isCartNotYetInPeerCarts =
							(currentPeerCarts ?? [])
								.every(
									peerCart =>
										peerCart.id !== cartPulse.cartId
								);

						return currentPeerCarts;
					}
				);

				if (isCartNotYetInPeerCarts)
				{
					const cart =
						await fetch(
							`/client/business/cart/peerCarts/${cartPulse.cartId}`,
							undefined,
							CartProfile
						);

					setCarts(
						currentPeerCarts =>
							upsertCartInCarts(
								cart,
								currentPeerCarts ??[]
							)
					);
				}
			}
		}
	);
	useWebSocketQuery(
		businessQuery,
		'CartOffline',
		notification =>
		{
			const cartOffline = CartOfflineProfile.deserialize(notification) as CartOffline;

			if (cartOffline.cartId !== ownCartId)
			{
				setCarts(
					currentPeerCarts =>
						removeCartFromCarts(
							cartOffline.cartId,
							currentPeerCarts ?? []
						)
				);
			}
		}
	);
	useWebSocketQuery(
		businessQuery,
		'CartDelete',
		notification =>
		{
			const cartDelete = CartDeleteProfile.deserialize(notification) as CartDelete;

			if (cartDelete.cartId !== ownCartId)
			{
				setCarts(
					currentPeerCarts =>
						removeCartFromCarts(
							cartDelete.cartId,
							currentPeerCarts ?? []
						)
				);
			}
		}
	);

	return carts ?? [];
}

function upsertCartInCarts(
	newCart: Cart,
	carts: Cart[]
): Cart[]
{
	if (carts.every(
		peerCart =>
			peerCart.id !== newCart.id
	))
	{
		return [
			...carts,
			newCart,
		];
	}
	else
	{
		return carts.map(
			currentCart =>
				currentCart.id === newCart.id
					? newCart
					: currentCart
		);
	}
}

function removeCartFromCarts(
	cartIdToRemove: number,
	carts: Cart[]
): Cart[]
{
	return carts.filter(
		cart =>
			cart.id !== cartIdToRemove
	);
}