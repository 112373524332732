import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { Reward } from '../../../../../Api/Order/Loyalty/Como/Reward';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import MenuEntry from '../../../../UI/menu_entry/MenuEntry';
import { ComoRewardRedeemBadge } from './ComoRewardRedeemBadge';
import { ComoRewardsStore } from './ComoRewardsStore';

interface ComoRewardTileProps
{
	store: ComoRewardsStore;
	reward: Reward;
	minHeight?: number;
	minImageHeight?: number;
	minImageWidth?: number;
}

export const ComoRewardTile: FC<ComoRewardTileProps> =
	({
		store,
		reward,
		minHeight,
		minImageHeight,
		minImageWidth,
	}) =>
	{
		const translate = useTranslate();

		const label = useMemo(() =>
		{
			if (reward.points === undefined)
				return undefined;
			else
			{
				const points = new Decimal(reward.points).mul(new Decimal(10).pow(-store.shoppingCartStore.currentOrderService.currency.decimalPlaces)).toString();

				return `${points} ${translate('Client-Loyalty-Como-Points')}`;
			}
		}, [reward.points, store.shoppingCartStore.currentOrderService.currency.decimalPlaces, translate]);

		const allAssetKeys = useObserver(() => store.shoppingCartStore.currentOrderService.allAssetKeys);

		const isRedeemed = useMemo(() =>
		{
			return allAssetKeys.includes(reward.id);
		}, [allAssetKeys, reward.id]);

		return <div
			style={{
				position: 'relative',
				display: 'flex',
				flexGrow: 1,
			}}
		>
			<MenuEntry
				image={{
					imageURL: reward.imageUrl,
				}}
				title={reward.name}
				description={reward.description}
				showDecriptionOnTile
				type="TILE"
				price={label}
				primaryBadges={
					isRedeemed
						? [
							<ComoRewardRedeemBadge />
						]
						: []
				}
				minHeight={minHeight}
				minImageHeight={minImageHeight}
				minImageWidth={minImageWidth}
				disabled={reward.redeemable === false}
				onClick={() =>
				{
					store.openReward(reward)
				}}
			/>
			{
				reward.redeemable === false &&
				<div
					style={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						background: 'rgba(0, 0, 0, 0.26)',
					}}
				/>
			}
		</div>;
	};
