import { Storage } from '../../../Bridge/Storage/Storage';
import { StorageVars } from '../../../Constants/StorageConstants';
import { getAuthUrl } from '../../../Util/Api/Resources/getAuthUrl';
import { toQueryParams } from '../../../Util/toQueryParams';
import { AuthenticationResultV3 } from '../AuthenticationResult';
import { getJwkSet } from './JwkSet';
import { validateToken } from './validateToken';

export async function resolveAuthentication(storage: Storage, code: string, state: string): Promise<AuthenticationResultV3>
{
	const redirectUri = `${window.location.origin}/auth`;

	const oidcState = storage.get(StorageVars.OidcState);
	const oidcCodeVerifier = storage.get(StorageVars.OidcCodeVerifier) ?? '';

	if (state !== oidcState)
	{
		storage.set(StorageVars.OidcState);

		throw new Error('Invalid state detected');
	}

	const body = {
		code: code,
		grant_type: 'authorization_code',
		client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
		redirect_uri: redirectUri,
		code_verifier: oidcCodeVerifier,
	};

	const response = await fetch(
		getAuthUrl('/oauth2/token'),
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: toQueryParams(body),
		},
	);

	const tokens = await response.json();
	const jwkSet = getJwkSet();

	const accessTokenPayload = await validateToken(jwkSet, tokens.access_token);
	const idTokenPayload = await validateToken(jwkSet, tokens.id_token);

	return {
		version: 'V3',
		accessToken: tokens.access_token,
		refreshToken: tokens.refresh_token,
		accessTokenPayload,
		idTokenPayload,
	};
}
