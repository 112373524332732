import { Box, Zoom } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import * as React from 'react';
import { FC, useContext } from 'react';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';

export const ComoRewardRedeemBadge: FC =
	() =>
	{
		const {brandingService} = useContext(EntranceContextRef);

		return <Zoom
			in
		>
			<Box
				sx={{
					borderRadius: '50%',
					// Solved in newer versions of Material-UI:
					// @ts-ignore
					backgroundColor: brandingService.tintColor.css,
					color: brandingService.tintContrastTextColor.css,
					height: 25,
					width: 25,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Check
					fontSize="small"
				/>
			</Box>
		</Zoom>;
	}
