import { ButtonBase, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/es/styles/withStyles';
import * as React from 'react';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Color } from '../../../Api/Other/Color';
import { ImageFit } from '../Image/ImageFit';
import { getMediaButtonImageBackgroundColor } from './api/getMediaButtonImageBackgroundColor';
import { MediaButtonBar } from './bar/MediaButtonBar';
import { MediaButtonBarHeight } from './bar/MediaButtonBarHeight';
import { useEffectiveIsDarkTextColor } from './bar/useEffectiveIsDarkTextColor';
import { MediaButtonBadges } from './MediaButtonBadges';
import { MediaButtonImage } from './MediaButtonImage';

const useStyles = makeStyles(theme => ({
	image: {
		position: 'relative',

		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
		},

		'&:hover, &:focus-visible': {
			zIndex: 1,
		},
	},
}));

export interface MediaButtonProps
{
	backgroundColor: Color,
	primaryBadges?: ReactNode[],
	secondaryBadges?: ReactNode[],
	captionHasShadow?: boolean,
	height?: number | string,
	image: string,
	imageFit?: ImageFit,
	imageIsTextContrastColorDark?: boolean,
	imageStyle?: CSSProperties,
	imageTextBackdropColor?: Color,
	imageTextColor?: Color,
	minHeight: number | string,
	minImageHeight?: number,
	minImageWidth?: number,
	onClick?: () => void,
	secondaryTitle?: string,
	description?: string,
	style?: CSSProperties,
	title: string,
	disabled?: boolean,
}

export const MediaButton: FC<MediaButtonProps> =
	({
		backgroundColor,
		primaryBadges,
		secondaryBadges,
		captionHasShadow,
		height,
		image,
		imageFit,
		imageIsTextContrastColorDark,
		imageStyle,
		imageTextBackdropColor,
		imageTextColor,
		minHeight,
		minImageHeight,
		minImageWidth,
		onClick,
		secondaryTitle,
		description,
		style,
		title,
		disabled,
	}) =>
	{
		const classes = useStyles();
		const hasText =
			title.trim().length > 0
			|| (secondaryTitle !== undefined && secondaryTitle.trim().length > 0);
		const [isImageLoading, setImageLoading] = useState(false);
		const startLoadingImage =
			useCallback(
				() =>
					setImageLoading(true),
				[],
			);
		const stopLoadingImage =
			useCallback(
				() =>
					setImageLoading(false),
				[],
			);
		const finalIsTextContrastColorDark =
			useEffectiveIsDarkTextColor(
				imageIsTextContrastColorDark,
				imageTextColor,
				imageTextBackdropColor,
			);
		const finalBackgroundColor =
			useMemo(
				() =>
					getMediaButtonImageBackgroundColor(
						image,
						isImageLoading,
						finalIsTextContrastColorDark,
						backgroundColor,
					),
				[backgroundColor, finalIsTextContrastColorDark, image, isImageLoading],
			);

		return <ButtonBase
			className={classes.image}
			focusRipple
			onClick={onClick}
			style={{
				alignItems: 'stretch',
				display: 'flex',
				flexDirection: 'column',
				height: height,
				minHeight: minHeight,
				width: '100%',
				backgroundColor: finalBackgroundColor,
				...style,
			}}
			disabled={disabled}
		>
			{
				image !== undefined &&
				<MediaButtonImage
					fit={imageFit}
					image={image}
					minImageHeight={minImageHeight}
					minImageWidth={minImageWidth}
					style={{
						marginBottom: !hasText || imageTextBackdropColor === undefined || imageTextBackdropColor.a < 1
							? undefined
							: MediaButtonBarHeight,
						...imageStyle,
						// filter: disabled ? 'grayscale(1)' : undefined,
					}}
					textBackdropColor={imageTextBackdropColor}
					onStartLoading={startLoadingImage}
					onEndLoading={stopLoadingImage}
				/>
			}
			<MediaButtonBadges
				primaryBadges={primaryBadges ?? []}
				secondaryBadges={secondaryBadges ?? []}
			/>
			{
				hasText &&
				<MediaButtonBar
					captionHasShadow={captionHasShadow !== false}
					secondaryTitle={secondaryTitle}
					description={description}
					textColor={imageTextColor}
					textBackdropColor={imageTextBackdropColor}
					textMode={
						finalIsTextContrastColorDark
							? 'dark'
							: 'light'
					}
					title={title}
				/>
			}
		</ButtonBase>;
	};