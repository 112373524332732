import { makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { getFileUrl } from '../../../../../../Util/Api/Resources/getFileUrl';
import { authorize } from '../../../../../authentication-provider/v3/authorize';
import { useStorage } from '../../../../../Root/StorageContextProvider';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { OrderBuilderStore } from '../../OrderBuilderStore';

const ComoTermsAndConditionsUrl = 'https://privacy.comosense.com/end-user-terms-of-use.html';

const useStyles = makeStyles(theme => ({
	loyaltyIntegrationLogo: {
		maxHeight: 100,
		width: 100,
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: theme.spacing(2),
	},
}));

interface ComoRewardOrderBuilderSegmentLoginSectionProps
{
	store: OrderBuilderStore;
}

export const ComoRewardOrderBuilderSegmentLoginSection: FC<ComoRewardOrderBuilderSegmentLoginSectionProps> =
	({
		store,
	}) =>
	{
		const storage = useStorage();
		const translate = useTranslate();

		const loyaltyIntegration = useObserver(() => store.businessStore.loyaltyIntegration);
		const loyaltyIntegrationLogoUrl = useObserver(() => store.businessStore.loyaltyIntegrationLogoUrl);

		const classes = useStyles();

		return <div
			className={classes.root}
		>
			{
				loyaltyIntegrationLogoUrl !== undefined &&
				<img
					alt="loyalty"
					className={classes.loyaltyIntegrationLogo}
					src={getFileUrl(loyaltyIntegrationLogoUrl)}
				/>
			}
			<Typography
				color="textSecondary"
			>
				{translate('Social-Login-Reward')}
			</Typography>
			<ActionButton
				id="social-login"
				onClick={
					() =>
						authorize(
							storage,
							undefined,
							false,
							loyaltyIntegrationLogoUrl === undefined
								? undefined
								: getFileUrl(loyaltyIntegrationLogoUrl),
							loyaltyIntegration === 'COMO'
								? ComoTermsAndConditionsUrl
								: undefined,
						)
				}
				fullWidth
				size="large"
				text={translate('Social-Login')}
				variant="outlined"
			/>
		</div>;
	};
