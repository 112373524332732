import { Box, IconButton, makeStyles } from '@material-ui/core';
import { Heart } from 'mdi-material-ui';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { getFileUrl } from '../../../../../Util/Api/Resources/getFileUrl';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';
import { MenuStore } from '../MenuStore';

const useStyles = makeStyles({
	Root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	heartShake: {
		animationDelay: '2s',
		animation: '$heartShake 2s ease',
	},
	'@keyframes heartShake': {
		'30%': {transform: 'scale(1.2)'},
		'40%, 60%': {transform: 'rotate(-20deg) scale(1.2)'},
		'50%': {transform: 'rotate(20deg) scale(1.2)'},
		'70%': {transform: 'rotate(0deg) scale(1.2)'},
		'100%': {transform: 'scale(1)'},
	},
});


interface MenuTopBarAppendixComoRewardsProps
{
	store: MenuStore;
}

export const MenuTopBarAppendixComoRewards = ({store}: MenuTopBarAppendixComoRewardsProps) =>
{
	const classes = useStyles();
	const screenWidth = useScreenWidth();
	const isSmallScreen = useMemo(() => screenWidth === 'xs', [screenWidth]);

	const onOpenPointShop = useCallback(
		() => store.businessStore.openComoRewardsPage(),
		[store],
	);

	return <Box
		className={classes.Root}
	>
		<IconButton
			color="inherit"
			size={isSmallScreen ? 'small' : 'medium'}
			onClick={onOpenPointShop}
			style={{padding: '12px'}}
			className={classes.heartShake}
		>
			{
				store.businessStore.loyaltyIntegrationIconUrl === undefined
					? <Heart />
					: <img style={{
						width: '24px',
						height: '24px',
					}}
						   alt="loyalty"
						   src={getFileUrl(store.businessStore.loyaltyIntegrationIconUrl)}>
					</img>
			}
		</IconButton>
	</Box>;
};
