import { CardContent, CardMedia, Divider, Grid, makeStyles, Theme } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useWebClient } from '../../../../../Bridge/Client/WebClientProvider';
import { ComoRewardInformation } from './ComoRewardInformation';
import { ComoRewardStore } from './ComoRewardStore';

const height = 300;
const fabHeight = 54;

const useStyles = makeStyles((theme: Theme) => ({
	topCard: {
		position: 'relative',
	},
	topCardMediaTitleBelowImage: {
		[theme.breakpoints.down('sm')]: {
			maxHeight: 'calc(100vh - 200px)',
		},
		[theme.breakpoints.up('md')]: {
			maxHeight: 'calc(100vh - 340px)',
		},
		minHeight: '35vh',
	},
	badgeRoot: {
		position: 'absolute',
		top: height - fabHeight / 2,
		bottom: theme.spacing(4),
		right: theme.spacing(2),
	},
	badge: {
		position: 'relative',
		right: 20,
		top: -10,
	},
	fab: {
		color: 'white',
	},
}));

interface ComoRewardProps
{
	store: ComoRewardStore;
}

export const ComoReward: FC<ComoRewardProps> =
	({
		store,
	}) =>
	{
		const {imageHeight, imageWidth} = useRewardImageDimensions(store);
		const client = useWebClient(true);
		const classes = useStyles();
		return useObserver(() =>
		{
			return <div
				style={{
					overflow: 'hidden',
				}}
			>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<div
							style={{
								position: 'relative',
							}}
						>
							<CardMedia
								image={store.reward.imageUrl}
								classes={{
									root: classes.topCardMediaTitleBelowImage,
								}}
								style={{
									backgroundSize: 'cover',
									height: imageHeight !== undefined && imageWidth !== undefined && !isNaN(imageHeight / imageWidth * client.viewportWidth)
										?
										imageHeight / imageWidth * client.viewportWidth
										:
										300,
								}}
							/>
							<Divider />
							<CardContent
								style={{
									columnWidth: 400,
									columnGap: 20,
									paddingTop: 20,
									paddingBottom: 20,
									paddingLeft: 20,
									paddingRight: 20,
								}}
							>
								<ComoRewardInformation store={store} />
							</CardContent>
						</div>
					</Grid>
				</Grid>
			</div>;
		});
	};

function useRewardImageDimensions(store: ComoRewardStore)
{
	const [imageHeight, setImageHeight] = useState<number | undefined>();
	const [imageWidth, setImageWidth] = useState<number | undefined>();

	const image = useMemo(() =>
	{
		const image = new Image();
		image.src = store.reward.imageUrl;
		return image;
	}, [store.reward.imageUrl]);
	useEffect(
		() =>
		{
			image.onload = () =>
			{
				setImageHeight(image.height);
				setImageWidth(image.width);
			};
		},
		[image],
	);
	return {imageHeight, imageWidth};
}
