import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, makeObservable } from 'mobx';
import { Reward } from '../../../../../Api/Order/Loyalty/Como/Reward';
import { postJsonAny } from '../../../../../Util/Api';
import { ComoRewardsStore } from '../Rewards/ComoRewardsStore';

export class ComoRewardStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	public readonly comoRewardsStore: ComoRewardsStore;

	public readonly reward: Reward;

	// ------------------------ Constructor -------------------------

	constructor(
		comoRewardsStore: ComoRewardsStore,
		reward: Reward
	)
	{
		super();

		makeObservable(
			this,
			{
				isRedeemed: computed,
				descriptionLines: computed,
				redeem: action.bound,
				remove: action.bound,
				setTabIndex: action.bound,
			},
		);

		this.comoRewardsStore = comoRewardsStore;
		this.reward = reward;
	}

	// ----------------------- Initialization -----------------------

	async initialize(): Promise<void> {}

	// -------------------------- Computed --------------------------

	get isRedeemed(): boolean
	{
		return this.comoRewardsStore.shoppingCartStore.currentOrderService.confirmedAssetKeys.includes(this.reward.id);
	}

	get descriptionLines(): string[]
	{
		return this.reward.description.split(/\n/);
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	async redeem(): Promise<void>
	{
		if (this.reward.points === undefined)
		{
			this.comoRewardsStore.shoppingCartStore.currentOrderService.setAssetKey(this.reward.id);
			await this.comoRewardsStore.shoppingCartStore.currentOrderService.checkOrderIfNecessary();
		}
		else
		{
			const assetKey = await this.redeemPointShopItem();

			await this.comoRewardsStore.reinitialize();

			if (this.comoRewardsStore.rewardsById.get(assetKey)?.redeemable)
			{
				this.comoRewardsStore.shoppingCartStore.currentOrderService.setAssetKey(assetKey);
				await this.comoRewardsStore.shoppingCartStore.currentOrderService.checkOrderIfNecessary();
			}
		}

		await this.close();
	}

	async remove(): Promise<void>
	{
		this.comoRewardsStore.shoppingCartStore.currentOrderService.removeAssetKey(this.reward.id);
		await this.comoRewardsStore.shoppingCartStore.currentOrderService.checkOrderIfNecessary();

		await this.close();
	}

	// ------------------------ Public logic ------------------------

	public close(): Promise<unknown>
	{
		return this.comoRewardsStore.businessStore.bridge.navigator.popScreen();
	}

	public setTabIndex(index: number): void
	{
		this.comoRewardsStore.setTabIndex(index);
	}

	// ----------------------- Private logic ------------------------

	private redeemPointShopItem(): Promise<string>
	{
		return postJsonAny<string>(
			'/client/business/loyalty/como/point-shop/redeem',
			{
				como_point_shop_item_id: this.reward.id
			},
		);
	}
}
