import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';
import { BusinessContextRef } from '../../../BusinessContext';
import { ShoppingCartConfiguration } from '../../../ShoppingCart/Configuration/ShoppingCartConfiguration';
import { ShoppingCartLine } from '../ShoppingCartLine';

const useStyles = makeStyles(() => ({
	card: {
		flex: '0 0 auto',
		display: 'flex',
		margin: 8,
	},
}));

interface AdditionalShoppingCartLineProps
{
	shoppingCartLine: ShoppingCartLine
	onRemove: (shoppingCartLine: ShoppingCartLine) => void
}

export const AdditionalShoppingCartLine: FC<AdditionalShoppingCartLineProps> =
	(
		{
			shoppingCartLine,
			onRemove,
		},
	) =>
	{
		const {
			businessStore,
		} = useContext(BusinessContextRef);
		const productCurrencyCode = useObserver(() => businessStore.business.productCurrencyCode);
		const changeQuantity = useCallback(
			(configuration, diff) => {
				shoppingCartLine.addQuantity(diff)
				if (shoppingCartLine.quantity <= 0)
					onRemove(shoppingCartLine);
			},
			[onRemove, shoppingCartLine]
		);
		const classes = useStyles();
		const productConfiguration = useObserver(() => shoppingCartLine.productConfiguration);
		const quantity = useObserver(() => shoppingCartLine.quantity);
		return <Card className={classes.card}>
			<ShoppingCartConfiguration
				configuration={productConfiguration}
				productCurrencyCode={productCurrencyCode}
				quantity={quantity}
				changeQuantity={changeQuantity}
				editable
				style={{
					borderBottom: 'none',
					flex: '0 0 auto',
				}}
			/>
		</Card>;
	};