import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { NegativeActionButton } from '../../../../UI/action-button/type/NegativeActionButton';
import { ComoRewardStore } from './ComoRewardStore';

interface ComoRewardDialogActionsProps
{
	store: ComoRewardStore;
}

export const ComoRewardDialogActions: FC<ComoRewardDialogActionsProps> =
	({
		store,
	}) =>
	{
		const translate = useTranslate();
		// use this state to keep track of the running promises, as otherwise the wrong button would become visible right before closing the dialog.
		const [isRedeemedOverride, setIsRedeemedOverride] = useState<boolean | undefined>(undefined);

		const label = useMemo(() =>
		{
			if (store.reward.points === undefined)
				return undefined;
			else
			{
				const points = new Decimal(store.reward.points)
					.mul(
						new Decimal(10)
							.pow(-store.comoRewardsStore.shoppingCartStore.currentOrderService.currency.decimalPlaces),
					)
					.toString();

				return `${points} ${translate('Client-Loyalty-Como-Points')}`;
			}
		}, [store.comoRewardsStore.shoppingCartStore.currentOrderService.currency.decimalPlaces, store.reward.points, translate]);

		const isRedeemed = useObserver(() => store.isRedeemed);
		const pointBalance = useObserver(() => store.comoRewardsStore.membership.points);

		return <>
			{
				store.reward.points !== undefined &&
				<Typography
					variant="subtitle1"
					color="textPrimary"
					style={{
						textAlign: 'left',
						marginRight: 'auto',
					}}
				>
					{
						label
					}
				</Typography>
			}
			{
				(isRedeemedOverride ?? isRedeemed)
					? <NegativeActionButton
						id="como-reward-dialog-remove-button"
						text={translate('Generic-Remove(NotDelete)')}
						onClick={() =>
						{
							setIsRedeemedOverride(true);
							return store
								.remove()
								.catch((e) =>
								{
									setIsRedeemedOverride(undefined);
									throw e;
								});
						}}
						size="medium"
						style={{
							alignSelf: 'stretch',
							margin: 0,
						}}
						icon={CloseIcon}
					/>
					: <ActionButton
						id="como-reward-dialog-add-button"
						text={
							store.reward.points === undefined
								? translate('Client-Order-Add[ItemToOrder]')
								: (store.reward.points > pointBalance
									? translate('Client-Loyalty-Como-Not-Enough-Points')
									: translate('Client-Loyalty-Como-Buy-With-Points'))
						}
						disabled={store.reward.points > pointBalance}
						onClick={() =>
						{
							setIsRedeemedOverride(false);
							return store
								.redeem()
								.then(
									() =>
										store.setTabIndex(0),
								)
								.catch((e) =>
								{
									setIsRedeemedOverride(undefined);
									throw e;
								});
						}}
						size="medium"
						style={{
							alignSelf: 'stretch',
							margin: 0,
						}}
						icon={ShoppingCartIcon}
					/>
			}
		</>;
	};