import { Box, Grid, Hidden, IconButton, makeStyles, SvgIconProps, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Property } from 'csstype';
import * as React from 'react';
import { ComponentType, createElement, FC, ReactNode, useMemo } from 'react';
import { Color } from '../../Api/Other/Color';
import { useRootContext } from '../../RootContext';
import { useIsKioskMode } from '../../Service/KioskService/Api/useIsKioskMode';
import { drawerWidth } from '../Page/Business/BusinessConstants';
import { CenteredPageContent } from './CenteredPageContent';

export interface PageTopBarProps
{
    icon?: ComponentType<SvgIconProps>;
    onIconClick?: () => void;
    topBarColor: Color;
    topBarContrastTextColor: Color;
    backgroundContrastTextColor?: Color;
    imageUrl?: string;
    title?: string;
    scrollYOffset?: number;
    sidebarEnabled: boolean;
    inline?: boolean;
    appendix?: ReactNode
}

export const topBarHeight = 64;
export const topBarHeightKiosk = 110;
export const topBarHeightMobile = 56;
const topBarFontSize = '25px';
const topBarKioskFontSize = '2.2rem';

export function getTopBarHeight(
    isSmallScreen: boolean,
    isKioskMode: boolean
)
{
    if (isSmallScreen)
    {
        return topBarHeightMobile;
    }
    else if (isKioskMode)
    {
        return topBarHeightKiosk;
    }
    else
    {
        return topBarHeight;
    }
}

function getTopBarFontSize(isKioskMode: boolean)
{
    if (isKioskMode)
    {
        return topBarKioskFontSize;
    }
    else
    {
        return topBarFontSize;
    }
}

export function getTopBarOpacity(offset: number): number
{
    return Math.min(offset / topBarHeight, 1);
}

export function getTopBarLogoOpacity(offset: number): number
{
    return Math.min((offset * offset / 1000), 1);
}

const useStyles = makeStyles<Theme, { lowPerformanceMode: boolean, isKioskMode: boolean }>(theme => ({
    Grid: ({isKioskMode}) => ({
        [theme.breakpoints.down('sm')]: {
            height:
                getTopBarHeight(
                    true,
                    isKioskMode
                ),
        },
        [theme.breakpoints.up('md')]: {
            height:
                getTopBarHeight(
                    false,
                    isKioskMode
                ),
        },
        width: '100%',
        overflowX: 'visible',
        overflowY: 'visible',
        zIndex: 100,
        top: 0,
        left: 0,
        right: 0,
    }),
    BackGround: ({lowPerformanceMode, isKioskMode}) => ({
        height: '100%',
        position: 'relative',
        top: 0,
        bottom: 0,
        left: 0,
        boxShadow: lowPerformanceMode
            ? undefined
            : `0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)`,

        [theme.breakpoints.down('sm')]: {
            marginBottom:
                -1 * getTopBarHeight(
                    true,
                    isKioskMode
                ),
        },
        [theme.breakpoints.up('md')]: {
            marginBottom:
                -1 * getTopBarHeight(
                    false,
                    isKioskMode
                ),
        },
    }),
    BackGroundOverhang: {
        [theme.breakpoints.down('sm')]: {
            right: 0,
        },
        [theme.breakpoints.up('md')]: {
            right: -24,
        },
    },
    BackGroundNoOverhang: {
        right: 0,
    },
    ContentWithOverhang: {
        [theme.breakpoints.up('md')]: {
            marginRight: drawerWidth,
        },
    },
    ChildGrid: ({isKioskMode}) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        padding: 0,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            height:
                getTopBarHeight(
                    true,
                    isKioskMode
                ),
        },
        [theme.breakpoints.up('md')]: {
            height:
                getTopBarHeight(
                    false,
                    isKioskMode
                ),
        },
        flex: '1 1 auto',
    }),
    ButtonContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 4,
        paddingRight: 10,
    },
    Title: ({isKioskMode}) => ({
        marginRight: 'auto !important',
        fontWeight: 'normal !important' as Property.FontWeight,
        fontSize: `${getTopBarFontSize(isKioskMode)} !important`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }),
    Appendix: {
        paddingRight: 4,
        marginLeft: 'auto',
    }
}));

export const PageTopBar: FC<PageTopBarProps> =
    ({
        sidebarEnabled,
        onIconClick,
        icon,
        scrollYOffset,
        imageUrl,
        title,
        topBarColor,
        topBarContrastTextColor,
        backgroundContrastTextColor = new Color(255, 255, 255, 1),
        inline,
        appendix
    }) =>
    {
    const {lowPerformanceMode} = useRootContext(true);
    const isKioskMode = useIsKioskMode();
    const classes =
        useStyles({
            lowPerformanceMode,
            isKioskMode,
        });

    const [topBarOpacity, logoOpacity] = useMemo(() =>
    {
        return scrollYOffset === undefined
            ? [1, undefined]
            : [getTopBarOpacity(scrollYOffset), getTopBarLogoOpacity(scrollYOffset)];

    }, [scrollYOffset]);

    const hamburgerButtonColor = useMemo(() =>
    {
        return new Color(
            topBarOpacity * topBarContrastTextColor.r + (1 - topBarOpacity) * backgroundContrastTextColor.r,
            topBarOpacity * topBarContrastTextColor.g + (1 - topBarOpacity) * backgroundContrastTextColor.g,
            topBarOpacity * topBarContrastTextColor.b + (1 - topBarOpacity) * backgroundContrastTextColor.b,
            topBarOpacity * topBarContrastTextColor.a + (1 - topBarOpacity) * backgroundContrastTextColor.a,
        );
    }, [backgroundContrastTextColor, topBarContrastTextColor, topBarOpacity]);

    return <Grid
        className={classes.Grid}
        id="page-top-bar"
        style={{
            position: inline
                ? 'relative'
                : lowPerformanceMode ? 'absolute' : 'fixed',
        }}
    >
        <div
            className={
                clsx(
                    classes.BackGround,
                    {
                        [classes.BackGroundOverhang]: sidebarEnabled,
                        [classes.BackGroundNoOverhang]: !sidebarEnabled,
                    },
                )
            }
            style={{
                backgroundColor: topBarColor.css,
                opacity: topBarOpacity,
            }}
        />
        <div>
            <CenteredPageContent
                className={clsx(
                    {
                        [classes.ContentWithOverhang]: sidebarEnabled
                    },
                )}
            >
                <div
                    className={classes.ChildGrid}
                >
                    {
                        icon && onIconClick &&
                        <div className={classes.ButtonContainer}>
                            <IconButton
                                style={{
                                    color: hamburgerButtonColor.css,
                                    zIndex: 101,
                                    fontSize: getTopBarFontSize(isKioskMode),
                                }}
                                onClick={onIconClick}
                                id="hamburger-button"
                            >
                                {
                                    createElement<SvgIconProps>(
                                        icon,
                                        {
                                            fontSize: 'inherit',
                                        }
                                    )
                                }
                            </IconButton>
                        </div>
                    }
                    {
                        imageUrl && !lowPerformanceMode &&
                        <Hidden xsDown={!!title}>
                            <img
                                alt="logo"
                                src={imageUrl}
                                style={{
                                    maxHeight: 40,
                                    maxWidth: 'calc(100% - 100px)',
                                    marginLeft: icon && onIconClick
                                        ?
                                        0
                                        :
                                        22,
                                    paddingLeft: scrollYOffset !== undefined
                                        ?
                                        (100 / Math.max(1, scrollYOffset * 2.8 - 2.5))
                                        :
                                        undefined,
                                    opacity: logoOpacity,
                                    zIndex: 101,
                                }}
                            />
                        </Hidden>
                    }
                    <Typography
                        variant="h1"
                        className={classes.Title}
                        style={{
                            opacity: topBarOpacity,
                            color: topBarContrastTextColor.css,
                        }}
                    >
                        {title}
                    </Typography>
                    {
                        appendix &&
                        <Box
                            className={classes.Appendix}
                            style={{
                                color: hamburgerButtonColor.css,
                                zIndex: 101,
                            }}
                        >
                            {
                                appendix
                            }
                        </Box>
                    }
                </div>
            </CenteredPageContent>
        </div>
    </Grid>;
};
